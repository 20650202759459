import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Container, Typography, TextField, Box, Button } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import axios from '../axiosConfig';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './custom-ag-theme-dark.css';
import ProbeForm from './ProbeForm';
import { useThemeContext } from '../ThemeContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

const ProbeResults = ({ selectedFolder }) => {
  const { mode } = useThemeContext();
  const [probedSubdomains, setProbedSubdomains] = useState([]);
  const [originalSubdomains, setOriginalSubdomains] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const gridRef = useRef();

  useEffect(() => {
    if (selectedFolder) {
      const fetchProbedSubdomains = async () => {
        try {
          const token = Cookies.get('token'); // Obtém o token dos cookies
          const response = await axios.get(`/${selectedFolder}/subdomains/httpx_final.json`, {
            headers: {
              Authorization: `Bearer ${token}`, // Adiciona o cabeçalho de autorização
            },
          });
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, ...JSON.parse(line) }));
          setProbedSubdomains(data);
          setOriginalSubdomains(data);
        } catch (error) {
          console.error(error);
          setProbedSubdomains([]);
        }
      };

      fetchProbedSubdomains();
    }
  }, [selectedFolder]);

  const columnDefs = [
    { headerName: 'Port', field: 'port', flex: 1, type: 'number', comparator: (valueA, valueB) => valueA - valueB },
    { headerName: 'URL', field: 'url', flex: 2 },
    { headerName: 'Title', field: 'title', flex: 2 },
    { headerName: 'Webserver', field: 'webserver', flex: 2 },
    { headerName: 'Content Type', field: 'content_type', flex: 2 },
    { headerName: 'Status Code', field: 'status_code', flex: 1 },
    { headerName: 'Content Length', field: 'content_length', flex: 1 },
    { headerName: 'Tech', field: 'tech', flex: 2 },
    { headerName: 'Body', field: 'body', flex: 2},
    { headerName: 'Host', field: 'host', flex: 2},
    { headerName: 'Headers', field: 'raw_header', flex: 2},
    { headerName: 'ASN', field: 'asn', flex: 1},
    { headerName: 'Timestamp', field: 'timestamp', flex: 1},
    { headerName: 'Vhost', field: 'vhost', flex: 1},
  ];

  const defaultColDef = {
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
    flex: 1
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const deduplicateByTitle = () => {
    const uniqueResults = probedSubdomains.reduce((acc, current) => {
      const existing = acc.find(item => item.title === current.title);
      if (!existing || (existing && existing.port > current.port)) {
        return [...acc.filter(item => item.title !== current.title), current];
      }
      return acc;
    }, []);
    setProbedSubdomains(uniqueResults);
  };

  const deduplicateByMultipleFields = () => {
    const uniqueResults = probedSubdomains.reduce((acc, current) => {
      const existing = acc.find(item =>
        item.title === current.title &&
        item.webserver === current.webserver &&
        item.content_length === current.content_length &&
        item.status_code === current.status_code &&
        item.content_type === current.content_type
      );
      if (!existing || (existing && existing.port > current.port)) {
        return [...acc.filter(item => 
          !(item.title === current.title &&
          item.webserver === current.webserver &&
          item.content_length === current.content_length &&
          item.status_code === current.status_code &&
          item.content_type === current.content_type)
        ), current];
      }
      return acc;
    }, []);
    setProbedSubdomains(uniqueResults);
  };

  const resetResults = () => {
    setProbedSubdomains(originalSubdomains);
  };

  useEffect(() => {
    const savedColumnState = JSON.parse(localStorage.getItem('probeResultsColumnState'));
    if (savedColumnState && gridRef.current) {
      gridRef.current.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  }, []);

  const onColumnMoved = useCallback(() => {
    const columnState = gridRef.current.columnApi.getAllColumns().map(col => ({
      colId: col.colId,
      hide: col.visible === false
    }));
    localStorage.setItem('probeResultsColumnState', JSON.stringify(columnState));
  }, []);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    const savedColumnState = JSON.parse(localStorage.getItem('probeResultsColumnState'));
    if (savedColumnState) {
      params.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  };

  return (
    <Container maxWidth="xl">
      <ProbeForm setProbedSubdomains={setProbedSubdomains} selectedFolder={selectedFolder} />
      <Typography variant="h5" gutterBottom align="center">
        Probe Results
      </Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary" onClick={onExportClick}>
          Export to CSV
        </Button>
        <Button variant="contained" color="secondary" onClick={deduplicateByTitle}>
          Deduplicate by Title
        </Button>
        <Button variant="contained" color="secondary" onClick={deduplicateByMultipleFields}>
          Deduplicate by Multiple Fields
        </Button>
        <Button variant="contained" color="inherit" onClick={resetResults}>
          Reset
        </Button>
      </Box>
      <div className={`ag-theme-alpine${mode === 'dark' ? '-dark' : ''}`} style={{ height: 800, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={probedSubdomains.filter(row =>
            columnDefs.some(
              column =>
                row[column.field] &&
                row[column.field].toString().toLowerCase().includes(searchQuery.toLowerCase())
            )
          )}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={100}
          enableCellTextSelection={true}
          onColumnMoved={onColumnMoved}
          onGridReady={onGridReady}
          animateRows={true}
        />
      </div>
      <ToastContainer position="bottom-right" />
    </Container>
  );
};

export default ProbeResults;
